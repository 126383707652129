body {
    background-color: #181D2C;
    color: white;
}

.aside-body {
    background-color: #151A27;
}

.aside-header {
    background-color: #151A27;
}

.content-header {
    background-color: #151A27;
}

.trade-table {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.trade-row {
    background-color: #181d2c !important;
}

.trade-row-alt {
    background-color: #1F2536 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: white;
}

.nav-aside .nav-link {
    color: #8A90A1;
}

.nav-aside svg {
    color: #8A90A1;
}

.nav-aside .nav-label {
    color: #8A90A1;
}

.nav-aside .nav-link:hover,
.nav-aside .nav-link:focus {
    color: white;
}

.modal-content {
    background-color: #181d2c;
}

.card {
    background-color: #1F2536;
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #1F2536;
}

.table {
    color: #8A90A1;
}

a {
    color: white;
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: white;
}

.form-control {
    color: #8A90A1;
}

.content-search .form-control:focus {
    color: #8A90A1;
}

.btn-primary {
    background-color: #1F86EE;
    border-color: #1F86EE;
    color: white;
}

.btn-outline-primary {
    border-color: #1F86EE;
    color: #1F86EE;
}

.nav-classic .nav-link {
    color: #8A90A1;
}

.nav-classic .nav-link:hover,
.nav-classic .nav-link:focus {
    color: white;
}

.nav-classic .nav-link:hover svg,
.nav-classic .nav-link:focus svg {
    color: white;
}

.badge-primary {
    background-color: #1F86EE;
}

.tx-color-01 {
    color: #8A90A1;
}

.btn-white {
    border: 1px solid #3F4556;
    background: none;
    color: white;
}

.btn-white:hover,
.btn-white:focus {
    color: white;
}

.form-control {
    background-color: #33394A;
    border-color: #8A90A1;
    color: white;
}

.form-control::placeholder {
    color: white;
}

.form-control::-ms-value {
    color: white;
}

.form-control:focus {
    background-color: #474c5b;
    border-color: #7cb2fe;
    outline: 0;
    color: white;
    box-shadow: 0 0 0 0.2rem rgb(1 104 250 / 25%);
}

.finance-selector {
    margin-top: -8px;
    margin-bottom: -8px;
    margin-right: -12px;
}

.flex-auto {
    flex: auto !important;
}

.flex-grow {
    flex-grow: 1 !important;
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #282f42;
}

.col-md-6.full-height > .card {
    height: 100%;
}